import React from "react";
import { Link } from "gatsby";
import { Layout } from "StaticPages/Layouts";
import Helmet from "react-helmet";
import "./SaaS.css";

export default () => {
    return <>
        <Helmet title="SaaS | VISUALIZ inc" />
        <Layout>
            <main className="SaaS">
                <h2 className="top-image" id="head">
                    <img src="/images/Saas_top.jpg" alt="Saasトップ" />
                </h2>
                <div className="BreadClumb">
                    <p><Link to="/">VISUALIZ inc</Link>＞<Link to="/service">サービス</Link>＞<Link to="/ai">AI</Link>＞<span>SaaS</span></p>
                </div>
                <h2 className="main-subttl MSize">あらゆる情報サイトの構築に、あらゆる情報サービスの展開に<br />SaaS（Software as a Service）</h2>
                <p className="main_about MSize Btm190">
                    それは例えるなら砂漠の中に点在している宝石のみを見つける仕組み。<br />宝石のみを手に入れるか、仕組みそのものを手に入れるか、お望みの形でのコンテンツ事業サービスを提供します。
                </p>

                <article className="NormalBgGray">
                    <p className="NonBorderH">SaaS（Software as a Service）LINE UP</p>
                    <h3 className="LRBorder HSpacingS">コンテンツ事業提供サービス<br />大規模情報サイトの構築サポート
                    </h3>
                    <p className="MwMiddle FSml">例えば数千のサイトからアルバイトや求人情報のみをクローリング。<br />或いは全ての自治体や法務局から変更された条例や法律のみをクローリングし、適切に設計されたデータベースへと自働でデータをアップ、これまで不可能と思われていた情報サービスの実現へ。私たちは情報サービスの運用に画期的なイノベーションを起こす事が出来るこのサービスを全てのノウハウと共に提供致します。
                    </p>

                    <section className="BgImgObi">
                        <img src="/images/Saas_LINE-UP.jpg" alt="Saasラインナップ" />
                        <div className="TileWrap">
                            <div>
                                <p>AZUREを使用した<br />クラウドシステムの<br />一括提供</p>
                            </div>
                            <div>
                                <p>クローラーシステムの<br />提供</p>
                            </div>
                            <div>
                                <p>パテントに関する<br />使用許可</p>
                            </div>
                            <div>
                                <p>アノテーションデータの<br />作成又は<br />コンサルティング</p>
                            </div>
                            <div>
                                <p>設定済AIの提供<br />（最大128分類程度）</p>
                            </div>
                            <div>
                                <p>コンテンツへの<br />情報自働アップシステム</p>
                            </div>
                        </div>
                    </section>
                    <p className="MwMiddle FSml">制作準備から公開まで、そして公開後まで最低限の人員で運用可能なシステムを提供できる画期的なコンテンツ事業サービスです。</p>
                    <p className="LinkButtonFull FullBlue"><Link to="#">お申し込み・お問い合わせ</Link></p>
                </article>

                <article className="ServiceType">
                        <h3 className="LRBorder HSpacingS">ビジネス情報提供サービス<br />
            ¥2,000/月から可能なサイト価値向上策
        </h3>
                        <p className="MwMiddle FSml">サイト全体の構築ではなく、自社サイトの価値向上に必要な情報の有償提供も行います。<br />
            士業の方々に向けて開始する最新の補助金・助成金ニュース、自治体・政府だけでなく行政特殊法人や財団法人など、あらゆる助成事業を行なっている団体から情報を収集し、その情報をAPIを通じ貴方のサイトへアップします。
            しかも再販OKなライセンス付のサービスです。
            </p>

                    <section className="BgImgObi">
                        <img src="/images/Saas_service.jpg" alt="Saasサービス" />
                        <div className="TileWrap">
                            <div>
                                <p>士業<br />補助金・助成金情報の提供</p>
                            </div>
                            <div>
                                <p>業界団体<br />その業界が行なっている<br />業務の全国入札情報
                                </p>
                            </div>
                            <div>
                                <p>企業<br />顧客が必要としている<br />入札情報</p>
                            </div>
                        </div>
                    </section>

                    <p className="MwMiddle FSml">業界団体のサイトには全国から上がってくる業務の入札情報を、映像者やカメラマンの方々が所属する協会には映像制作のプロポーザル情報を提供します。<br />また企業には自らの顧客が必要としている全国の入札情報を貴社サイトに取り入れていただくことで、サイト自体の価値を上げることが可能、サービスは月々¥2,000から提供可能です。<br />自社のサイトには表示したい場所に一行ソースを記入するだけ。<br />たった数分間で貴社の価値をアップするBIDnavi APIをどうぞお使いください。</p>
                    <p className="LinkButtonFull FullBlue"><Link to="#">お申し込み・お問い合わせ</Link></p>
                    <p className="LinkButtonFull FullGray"><Link to="#">ビジネス情報提供サービスページを見る</Link></p>
                </article>
            </main>
        </Layout>
    </>;
};
